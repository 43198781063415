
























import { AnonymousInvestorPlatformClient } from '@/client'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ResetPassword extends Vue {
  newPassword: string = ''

  async resetPassword() {
    const client = new AnonymousInvestorPlatformClient(process.env.VUE_APP_API_ENDPOINT!)
    const token = this.$route.query.token as string
    if (!this.newPassword) {
      return
    }

    await client.resetPassword(token, this.newPassword)
    alert('Password updated')
    this.$router.push('/login')
  }
}
